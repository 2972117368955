// @flow
import React, {Component} from "react"
import gql from 'graphql-tag';

import {Query} from "@apollo/client/react/components";
import {graphql, withApollo} from "@apollo/client/react/hoc";

import {compose, withProps} from "recompose";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ServiceTicketListAllQuery from './../../graphql/queries/serviceTicket-SearchAll.graphql';
import TypeDefinitionAttributeListQuery from './../../graphql/queries/typeDefinitionAttributeList.graphql';
import ServiceTicketListItem from "../ServiceTicketList/ServiceTicketListItem";
import _ from "lodash";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import {getPathText} from "../ComponentPathBreadcrumb";
import {Modal, Spin, Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import Toolbar from "../Toolbar";
import ServiceTicketMultiAction from "../ServiceTicketMultiAction";
import ServiceTicketStateBadge from "../ServiceTicketStateBadge";
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import FillQueryTable from "../QueryTable/fill";
import NavigationContainer from "../NavigationContainer";
import ServiceTicketsCreateSelectionBookmark from "../ServiceTicketsCreateSelectionBookmark";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql";
import withTheme from "../../hoc/withTheme";
import withProfile from "../../hoc/withProfile";
import {objectToFilter} from "../../utils/queryFilter";
import tdaToQuery from "../../utils/tdaToQuery";
import statics from "../../statics";
import tdaToColumn from "../../utils/tdaToColumn";
import OneLineLabel from "../OneLineLabel";
import {DragSource} from 'react-dnd';
import BodyRow from './BodyRow';

type Props = {
    onChange?: Function,
    history: {
        push: Function,
    },
    params: {
        parentId?: number,
    },
    variables: any,
    t: Function,
    ServiceTicketListField: query,
    onReset?: Function,
    bookmarkCollapsed?: boolean
}

type State = {
    selectedItems: any[],
    showMultiAction: boolean,
    selectedAction: string
}

class ServiceSearchResults extends Component<Props, State> {

    state = {
        selectedItems: [],
        showMultiAction: false,
        showSelectionForm: false,
        loadingSelection: false,
        selectedAction: ''
    };

    componentDidMount(){
        this.props.onRef(this);

        const {bookmarkQuery} = this.props;
        if (bookmarkQuery && bookmarkQuery.error) {
            this.onReset();
        }
    };

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params)) {
            this.onFilterReset();
        }
    }

    onFilterReset() {
        this.setState({
            selectedItems: []
        })
    };

    onChangeOrder = (orderKey, asc) => {
        const {params, onChange} = this.props;
        if (onChange) {
            const order = {};
            order[orderKey] = asc ? 1 : -1;
            onChange({
                ...params,
                order
            })
        }
    };

    onReset = () => {
        const {history} = this.props;
        history.push(`/service/search/`);
    };

    renderItem = (item) => {
        return <ServiceTicketListItem key={item.id} onClick={() => {
            const {history} = this.props;
            history.push(`/service/${item.id}`);
        }} item={item}/>
    };

    _handleSelectionAction = () => {

        this.setState({
            showMultiAction: true
        });

    };

    _hideMultiActionModal = () => {
        this.setState({
            showMultiAction: false,
            enableModalFooter: false,
        });
    };

    selectAll = () => {

        const {client, variables} = this.props;

        const vars = {...variables};
        vars.params.start = 0;
        vars.params.limit = undefined;

        this.setState({
            loadingSelection: true
        }, () => {
            client.query({
                query: ServiceTicketListAllQuery,
                variables
            }).then((res) => {

                this.setState({
                    loadingSelection: false,
                    selectedItems: _.get(res, 'data.serviceTicketList.serviceTickets', []).map(item => item.id)
                });

            })
        })


    };

    _handleSaveSelection = () => {
        this.setState({
            showSelectionForm: true,
        });
    };

    _hideSelectionModal = () => {
        this.setState({
            showSelectionForm: false
        })
    };

    getQuery = () => {
        return gql`
            query serviceTicketListSearch($params: ServiceTicketListParams) {
                serviceTicketList(params: $params){
                    total
                    serviceTickets {
                        id
                        name
                        key
                        insert
                        update
                        serviceTicketState {
                            index
                            name
                        }
                        serviceTicketType {
                            index
                            name
                        }
                        ${
                            tdaToQuery(
                                _.get(this.props,'serviceTicketColumnsQuery.typeDefinitionAttributeList.typeDefinitionAttributes',[]),
                                {
                                    customQueries: {
                                        'serviceTicketType.name': 'serviceTicketType { index name } ',
                                        'serviceTicketState.index': 'serviceTicketState { index name } ',
                                        'component.name': 'component { id path { id name shared } ... on ComponentProduct { id name } ... on ComponentMachine { id name } ... on ServiceTicketComponent { id name } } ',
                                        'component.serialNo': 'component { id ... on ComponentProduct { id serialNo } ... on ComponentMachine { id serialNo } ... on ServiceTicketComponent { id serialNo } } ',
                                        'component.product.catalogNo': 'component { id ... on ComponentProduct { id product { id catalogNo } } ... on ComponentMachine { id catalogNo } ... on ServiceTicketComponent { id product { id catalogNo } } } ',
                                        'lastServiceAction.serviceActionType.name': `lastServiceAction { id date serviceActionType { id name code } } `,
                                        'component.drawingNumber': `component { id ... on ComponentMachine { drawingNumber } ... on ComponentStation { drawingNumber } } `
                                    }
                                }
                            )
                        }
                    }
                }
            }

        `;
    };

    render() {

        const { t, params, themeConfig, resources, variables} = this.props;
        const {enableModalFooter, selectedItems} = this.state;

        const rowSelection = {
            selectedRowKeys: selectedItems,
            hideDefaultSelections: true,
            onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected){
                    this.selectAll();
                }else {
                    this.setState({
                        selectedItems: []
                    });
                }
            },
            onSelect: (record, selected, selectedRows) => {
                this.setState({
                    selectedItems: selectedRows.map(item => item.id || item)
                });
            },
        };

        const order = _.get(params, 'order');
        let orderKey, orderDirection = null;

        if (order) {
            const orderInfo = order.split('-');
            orderDirection = _.first(orderInfo) === "" ? "descend" : "ascend";
            orderKey = _.last(orderInfo);

        }


        const configColumns = [
            {
                title: t('State'),
                dataIndex: ['serviceTicketState', 'index'],
                key: 'serviceTicketState.index',
                width: themeConfig["@service-ticket-status-width"] || 100,
                render: (text, record) => {
                    return <Link to={`/service/${record.id}`}><ServiceTicketStateBadge
                        state={record.serviceTicketState}/></Link>
                }
            },

            {
                title: t('Key'),
                dataIndex: 'key',
                key: 'key',
                width: 150,
                sorter: true,
                render: (text, record) => <Link
                    className={"text-link"}
                    to={`/service/${record.id}`}><ServiceTicketTypeIcon index={_.get(record,'serviceTicketType.index')}/> {text}</Link>
            },

            {
                title: t('Component'),
                dataIndex: ['component', 'name'],
                key: 'component.name',
                width: 200,
                render: (text, record) => {
                    const read = _.get(record, 'component.access.read', true);
                    const path = _.get(record, 'component.path', []) || [];
                    const pathText = getPathText(path);
                    const label = <Link
                        className={"text-link"}
                        to={`/infrastructure/${_.get(record, 'component.id')}`}>{_.get(record, 'component.name')}</Link>;

                    if(read){
                        if (path.length > 1) {
                            return <OneLineLabel>
                                <Tooltip title={pathText} mouseEnterDelay={0.6}>
                                    {label}
                                </Tooltip>
                            </OneLineLabel>
                        }

                        return <OneLineLabel>
                            <Tooltip title={label} mouseEnterDelay={0.6}>
                                {label}
                            </Tooltip>
                        </OneLineLabel>;
                    }
                    return _.get(record, 'component.name')
                },
            },
            {
                title: t('Client'),
                dataIndex: ['client', 'name'],
                key: 'client.name',
                render: (text, record) => {
                    return <OneLineLabel>
                        <Tooltip title={text} mouseEnterDelay={0.6}>
                            {text}
                        </Tooltip>
                    </OneLineLabel>
                },
            },
            {
                dataIndex: 'insert',
                sorter: true
            },
            {
                title: t('Last Action'),
                dataIndex: ['lastServiceAction', 'serviceActionType', 'name'],
                key: 'lastServiceAction.serviceActionType.name',
                ellipsis: true,
                render: (text, record) => {
                    return `${_.get(record, 'lastServiceAction.serviceActionType.code')}: ${_.get(record, 'lastServiceAction.serviceActionType.name')}`
                }
            }
        ];


        const columns =  _.get(this.props,'serviceTicketColumnsQuery.typeDefinitionAttributeList.typeDefinitionAttributes',[]).map((tda) => {
            const configCol = _.find(configColumns, {key: tda.index});
            let tdaCol = tdaToColumn(tda);
            if (configCol){
                tdaCol = {
                    ...tdaCol,
                    ...configCol,
                    title: tdaCol.title || configCol.title,
                }
            }

            if (orderKey === tda.index) {
                tdaCol.defaultSortOrder = orderDirection
            }


            return tdaCol
        });


        const selectedServiceTicketTypes = _.chain(this.state.selectedItems).map(item => _.get(item,'serviceTicketType.index')).uniq().value();

        const rowSource = {
            beginDrag(props) {
                return {
                    id: _.get(props, 'data-row-key', null),
                };
            },
        };

        const DragableBodyRow = DragSource('bookmark', rowSource, connect => ({
            connectDragSource: connect.dragSource(),
        }))(BodyRow);

        const components = !this.props.bookmarkCollapsed
            ? {
                body: {
                    row: DragableBodyRow,
                }
            }
            : {};

        return <div className={"full-size overflow-hidden"}>

            <NavigationContainer navigationBar={<div>
                {this.state.selectedItems.length ?
                    <Toolbar
                        style={{height: 41}}
                        renderLeft={() => <div>
                            <Button
                                icon={'snippets'}
                                size={'small'}
                                onClick={this._handleSelectionAction}>{t('Action with total tickets', {total: this.state.selectedItems.length})}</Button>
                            <Button
                                className={"ml-10"}
                                icon={'save'}
                                size={'small'}
                                onClick={this._handleSaveSelection}>{t('Save total selection', {total: this.state.selectedItems.length})}</Button>
                        </div>}
                        borderBottom={true}/>
                    : null}
                {this.state.loadingSelection ?
                    <div style={{height: 41, paddingTop: 8, paddingLeft: 16}}>
                        <Spin size={"small"}/>
                    </div>
                    : null}
            </div>}>

                <Query
                    query={this.getQuery()}
                    variables={variables}
                >
                    {(query) => {

                        const tickets = _.get(query, 'data.serviceTicketList.serviceTickets', []);
                        const maxBadgeLength = _.max(tickets.map((item) => _.get(item, 'serviceTicketState.name', '').length));
                        _.set(_.find(columns, {key: 'serviceTicketState.index'}), 'width', (maxBadgeLength * 8) + 20 || 100);

                        return <div className={"full-size position-absolute"}>
                            <Modal
                                width={this.state.selectedAction === 'addFinalTest' ? 900 : 600}
                                destroyOnClose
                                visible={this.state.showMultiAction}
                                title={t('Multi Action with TICKET_COUNT service tickets', {count: this.state.selectedItems.length})}
                                footer={enableModalFooter ? [
                                    <Button key={'close'} type={'primary'}
                                            onClick={this._hideMultiActionModal}>{t('Close')}</Button>
                                ] : null}
                                onCancel={this._hideMultiActionModal}
                                onOk={this._hideMultiActionModal}
                            >
                                <ServiceTicketMultiAction
                                    onDone={() => {
                                        this.setState({
                                            enableModalFooter: true
                                        });
                                        query.refetch();
                                    }}
                                    onDelete={() => {
                                        this.setState({
                                            enableModalFooter: true,
                                            showMultiAction: false,
                                            selectedItems: []
                                        });
                                    }}
                                    onSelectAction={(action) => {
                                        this.setState({
                                            selectedAction: action
                                        });
                                    }}
                                    serviceTicketIds={this.state.selectedItems}
                                    preferredServiceTicketType={selectedServiceTicketTypes.length === 1 ? _.first(selectedServiceTicketTypes) : undefined}
                                />
                            </Modal>
                            <Modal
                                destroyOnClose
                                visible={this.state.showSelectionForm}
                                title={t('Save selection')}
                                footer={null}
                                onCancel={this._hideSelectionModal}
                                onOk={this._hideSelectionModal}
                            >
                                <ServiceTicketsCreateSelectionBookmark
                                    onCreated={() => {
                                        this._hideSelectionModal();
                                        this.setState({
                                            selectedItems: []
                                        });
                                    }}
                                    serviceTicketIds={this.state.selectedItems}
                                />
                            </Modal>
                            <FillQueryTable
                                scrollWidth={1400}
                                components={components}
                                size={'small'}
                                rowSelection={rowSelection}
                                rowKey={"id"}
                                columns={columns}
                                itemsKeyPath={'serviceTicketList.serviceTickets'}
                                totalKeyPath={'serviceTicketList.total'}
                                sortKeyPath={'params.sort'}
                                query={query}
                                onChange={(pagination, filters, sorter) => {
                                    const sortObject = {};
                                    if (sorter.field && sorter.order) {
                                        this.props.onChange({
                                            ...this.props.params,
                                            order: `${sorter.order === "descend" ? "-" : ''}${sorter.field}`
                                        });
                                    }else {
                                        this.props.onChange({
                                            ...this.props.params,
                                            order: null
                                        });
                                    }

                                }}
                            />
                        </div>

                    }}
                </Query>



            </NavigationContainer>




        </div>
    }


}


const filterBlacklist = ['search', 'query', 'order', 'fields', 'selectionBookmark', 'conditionOperator'];
export const filterFromProps = (props: Props) => {

    if (!props || !props.params) return null;

    const filter = Object.keys(props.params).reduce((acc, key) => {
        if (filterBlacklist.indexOf(key) === -1 && key && key.length) {
            // serviceActionSearch gibt es nur für Xenon und wird nicht im filter-Objekt gesendet, sondern extra
            if (key !== "serviceActionSearch") {
                acc[key] = props.params[key]
            }
        }

        return acc
    }, {});

    const result = objectToFilter(filter);
    ["insert","update"].forEach((key) => {
        if (result[key]){
            const from = _.chain(props.params).get(key).get("[0]").value();
            const to = _.chain(props.params).get(key).get("[1]").value();
            let range = undefined;
            if (from || to){
                range = {};
                if (from){
                    range.from = from
                }
                if (to){
                    range.to = to
                }
            }
            if (range) {
                result[key] = {
                    operator: 'range',
                    range
                }
            } else {
                delete result[key]
            }
        }
    });


    const data = _.get(props,'bookmarkQuery.bookmark.data');
    if (data){
        const bookmarkData = JSON.parse(data);
        const serviceTicketIds = _.get(bookmarkData,'serviceTicketIds',[]);
        if (serviceTicketIds.length){
            result["id"] = {
                value: serviceTicketIds
            }
        }

    }

    return result;
};

export const orderFromProps = (props: Props) => {
    let result = {

    };

    const order = _.get(props, 'params.order')
    if (order) {
        const orderInfo = order.split('-');
        const direction = _.first(orderInfo) === "" ? "DESC" : "ASC";
        const orderKey = _.last(orderInfo);
        result[orderKey] = direction;
    }else {
        result = {
            insert: 'DESC'
        };
    }

    return result;

};

export const searchParamsFromProps = (props: Props) => {
    const {query, fields} = _.get(props, 'params', {});
    if (!query || !query.length) return undefined;

    const result = {
        query: null,
        fields: _.get(props, 'ServiceTicketListField.__type.enumValues', ["componentName", "key", "serialNo", "catalogNo"]).map((item) => _.get(item, 'name', item))
    };
    if (query) {
        result.query = query
    }
    if (fields) {
        result.fields = fields
    }

    return result.query ? result : undefined;
};

export default compose(
    withRouter,
    withTranslation(),
    graphql(BookmarkQuery,{
        name: 'bookmarkQuery',
        skip: (props) => !_.get(props,'params.selectionBookmark'),
        options: (props) => {
            return {
                variables: {
                    bookmarkId: _.get(props,'params.selectionBookmark')
                }
            }
        }
    }),
    waitWhileLoading('bookmarkQuery',null, {
        optional: true,
        allowErrors: true,
        allowToasts: true
    }),
    graphql(Enums, {
        name: 'ServiceTicketListField',
        options: () => ({
            variables: {
                name: 'ServiceTicketListSearchFields',
            }
        }),
    }),
    waitWhileLoading('ServiceTicketListField', '__type.enumValues'),
    graphql(TypeDefinitionAttributeListQuery,{
        name: 'serviceTicketColumnsQuery',
        options: () => ({
            variables: {
                index: 'serviceTicketColumns',
            }
        }),
    }),
    waitWhileLoading('serviceTicketColumnsQuery'),
    withApollo,
    withTheme(),
    withProfile(),
    withProps((props) => {
        const filter = filterFromProps(props);
        const variables = {
            params: {
                filterOptions: _.get(props, 'params.conditionOperator') ? {
                    conditionOperator: _.get(props, 'params.conditionOperator')
                } : undefined,
                filter: filter && Object.keys(filter).length ? filter : undefined,
                serviceActionSearch: _.get(props, 'params.serviceActionSearch') ?
                    { value: _.get(props, 'params.serviceActionSearch')} : undefined,
                sort: orderFromProps(props),
                search: searchParamsFromProps(props),
                start: 0,
                limit: statics.defaultPagingSize
            }
        };

        return {
            variables
        }
    })
)(ServiceSearchResults);
