// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import Panel from "../Panel";
import _ from "lodash";

import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import FillQueryTable from "../QueryTable/fill";
import {Link} from "react-router-dom";
import OneLineLabel from "../OneLineLabel";
import {getPathText} from "../ComponentPathBreadcrumb";
import Toolbar from "../Toolbar";
import {toastError} from "../../utils/toast";
import DeleteServiceTicketSubscription from "../../graphql/mutations/deleteServiceTicketSubscription.graphql";
import ServiceTicketStateBadge from "../ServiceTicketStateBadge";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import withTheme from "../../hoc/withTheme";
import NotificationSetting from "../../graphql/queries/notificationSetting.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import tdaToColumn from "../../utils/tdaToColumn";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";

type Props = {
    t: Function,
    deleteServiceTicketSubscriptionMutation: Function,
    id: any,
    serviceTicketColumnsQuery: any
}

type State = {
    selectedRowIds: any,
    loading: boolean
}

class SubscribedServiceTickets extends Component<Props, State> {

    state = {
        selectedRowIds: [],
        loading: false
    };

    _handleUnsubscribe = () => {
        const {deleteServiceTicketSubscriptionMutation} = this.props;

        this.setLoading(true)
            .then(() => {
                const promises = this.state.selectedRowIds.map(id => deleteServiceTicketSubscriptionMutation({
                    variables: {
                        id
                    }
                }));
                return Promise.all(promises);
            })
            .catch(toastError)
            .finally(() => {
                this.setState({
                    selectedRowIds: [],
                });
                this.setLoading(false);
            })
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {t, setting, themeConfig, notificationSettingQuery} = this.props;
        const {selectedRowIds} = this.state;

        const rowSelection = {
            selectedRowKeys: selectedRowIds,
            hideDefaultSelections: true,
            onSelect: (record, selected, selectedRows) => {
                this.setState({
                    selectedRowIds: selectedRows.map(item => item.id || item)
                });
            },
        };

        const configColumns = [
            {
                title: t('State'),
                dataIndex: 'serviceTicketState.index',
                key: 'serviceTicketState.index',
                width: themeConfig["@service-ticket-status-width"] || 70,
                render: (text, record) => <Link to={`/service/${record.id}`}><ServiceTicketStateBadge state={record.serviceTicketState} /></Link>
            },

            {
                title: t('Key'),
                dataIndex: 'key',
                key: 'key',
                width: 150,
                sorter: true,
                render: (text, record) => <Link
                    className={"text-link"}
                    to={`/service/${record.id}`}><ServiceTicketTypeIcon index={_.get(record,'serviceTicketType.index')}/> {text}</Link>
            },

            {
                title: t('Component'),
                dataIndex: 'component.name',
                key: 'component.name',
                width: 200,
                render: (text, record) => {
                    const read = _.get(record, 'component.access.read', true);
                    const path = _.get(record, 'component.path', []) || [];
                    const pathText = getPathText(path);
                    const label = <Link
                        className={"text-link"}
                        to={`/infrastructure/${_.get(record, 'component.id')}`}>{_.get(record, 'component.name')}</Link>;

                    if(read){
                        if (path.length > 1) {
                            return <OneLineLabel><Tooltip title={pathText}>
                                {label}
                            </Tooltip></OneLineLabel>
                        }

                        return label;
                    }
                    return _.get(record, 'component.name')
                },
            },
            {
                dataIndex: 'insert'
            },
            {
                title: t('Last Action'),
                dataIndex: 'lastServiceAction.serviceActionType.name',
                key: 'lastServiceAction.serviceActionType.name',
                ellipsis: true,
                render: (text, record) => {
                    return `${_.get(record, 'lastServiceAction.serviceActionType.code')}: ${_.get(record, 'lastServiceAction.serviceActionType.name')}`
                }
            }
        ];

        const columns =  _.get(this.props,'serviceTicketColumnsQuery.typeDefinitionAttributeList.typeDefinitionAttributes',[]).map((tda) => {
            const configCol = _.find(configColumns, {dataIndex: tda.index});
            let tdaCol = tdaToColumn(tda);
            if (configCol){
                tdaCol = {
                    ...tdaCol,
                    ...configCol,
                    title: tdaCol.title || configCol.title,
                }
            }
            return tdaCol
        });

        return <Panel title={t(setting.name)} style={{margin: 13}}>
            {selectedRowIds.length ? <Toolbar
                renderLeft={() => <Button onClick={this._handleUnsubscribe}>{t('Unsubscribe')}</Button>}
            /> : null}
            <FillQueryTable
                loading={this.state.loading}
                rowSelection={rowSelection}
                style={{borderRadius: 0}}
                columns={columns}
                size={'middle'}
                rowKey={"id"}
                query={notificationSettingQuery}
                itemsKeyPath={'notificationSetting.serviceTicketList.serviceTickets'}
                totalKeyPath={'notificationSetting.serviceTicketList.total'}
            />
        </Panel>
    }
}

export default compose(
    graphql(TypeDefinitionAttributeListQuery,{
        name: 'serviceTicketColumnsQuery',
        options: () => ({
            variables: {
                index: 'serviceTicketColumns',
            },
            fetchPolicy: 'network-only'
        }),
    }),
    waitWhileLoading('serviceTicketColumnsQuery'),
    graphql(NotificationSetting, {
        name: 'notificationSettingQuery',
        options: (props: Props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only'
        }),
    }),
    waitWhileLoading('notificationSettingQuery', 'notificationSetting.serviceTicketList.serviceTickets'),
    graphql(DeleteServiceTicketSubscription, {
        name: 'deleteServiceTicketSubscriptionMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: NotificationSetting,
                variables: {
                    id: props.id
                },
                fetchPolicy: 'network-only'
            }]
        }),
    }),
    withTranslation(),
    withTheme(),
    withProps((props) => ({
        setting: _.get(props, 'notificationSettingQuery.notificationSetting', {})
    }))
)(SubscribedServiceTickets);
